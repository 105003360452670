const axios = require('axios').default
import { WISESHEETS_API_URL } from './constants'

export const httpClient = axios.create({
    baseURL: WISESHEETS_API_URL,
    timeout: 60000,
})

// Add a request interceptor
httpClient.interceptors.request.use(
    async (config) => {
        // Add auth token to header of each request if available
        const token = window.localStorage.getItem('token')
        if (config.headers !== undefined) {
            if (token !== null) {
                config.headers.Authorization = token
            }
        }
        return config
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error)
    }
)
