import { checkToken, handleError } from './utils'
import { httpClient } from './http-client'
import { demoFundTickers } from './constants'

/**
 * Get ETF data
 * @customfunction
 * @helpurl https://blog.wisesheets.io/the-ultimate-guide-to-using-wisesheets/
 * @param {string[][]} symbols ETF symbol.
 * @param {string[][]} parameters Data point(s) to retrieve.
 * @returns {any[][]} Data or some error.
 */
// @ts-ignore
async function WISEFUNDS(symbols, parameters) {
    if (symbols.flat().length > 1001) {
        return [['Please select less than 1,000 tickers to get data.']]
    }
    const symbolsToCheck = symbols
        .flat()
        .map((symbol: string) =>
            symbol.toUpperCase().replace('^', '%5E').trim()
        )

    for (let i = 0; i < symbolsToCheck.length; i++) {
        if (!symbolsToCheck[i]) {
            return [['Please enter a symbol.']]
        }
    }
    const { error } = checkToken(symbolsToCheck, demoFundTickers)

    if (error) {
        return error
    }

    try {
        const data = await httpClient.post(
            '/api/private/functions/wise-funds',
            {
                symbols,
                parameters,
                platform: 'EXCEL',
            }
        )
        return data.data
    } catch (e) {
        return handleError(e)
    }
}

CustomFunctions.associate("WISEFUNDS", WISEFUNDS);