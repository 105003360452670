import { demoTickers } from './constants'
import { httpClient } from './http-client'
import { checkToken, handleError } from './utils'

/**
 * Get live and historical stock data.
 * @customfunction
 * @helpurl https://blog.wisesheets.io/the-ultimate-guide-to-using-wisesheets/
 * @param {string[][]} symbols Company symbol.
 * @param {string[][]} parameters Data point(s) to retrieve.
 * @param {string[][]} periods Timeframe(s) which the data is from. Either a year, “TTM”, “LY”, “LY-1” etc and “LQ”, “LQ-1” etc.
 * @param {string[][]} [quarter] Of period (optional).
 * @param {number} [divisor] Divide the output by the divisor (optional). For example 1000000 to get the data in millions.
 * @returns {any[][]} Data or some error.
 */
// @ts-ignore
async function WISE(symbols, parameters, periods, quarter, divisor) {
    if (symbols.flat().length > 1001) {
        return [['Please select less than 1,000 tickers to get data.']]
    }
    // html encoding for ^
    const symbolsToCheck = symbols
        .flat()
        .map((symbol: string) =>
            symbol.toUpperCase().replace('^', '%5E').trim()
        )
    for (let i = 0; i < symbolsToCheck.length; i++) {
        if (!symbolsToCheck[i]) {
            return [['Please enter a symbol.']]
        }
    }

    // Check if user is subscribed
    const { error } = checkToken(symbolsToCheck, demoTickers)
    if (error) {
        return error
    }

    try {
        const data = await httpClient.post('/api/private/functions/wise', {
            symbols,
            parameters,
            periods,
            quarter,
            divisor,
            platform: 'EXCEL',
        })
        return data.data
    } catch (e) {
        return handleError(e)
    }
}

CustomFunctions.associate("WISE", WISE);