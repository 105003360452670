import { demoTickers } from './constants'
import { httpClient } from './http-client'
import { checkToken, excelDateToJSDate, handleError } from './utils'

/**
 * Get live and historical stock options data.
 * @customfunction
 * @helpurl https://blog.wisesheets.io/the-ultimate-guide-to-using-wisesheets/
 * @param {string[][]} symbols_or_contract_id Company symbols or contract ID.
 * @param {string[][]} type Put or Call.
 * @param {string[][]} parameters Data point(s) to retrieve.
 * @param {string[][]} [date] Date of contract(s) (optional).
 * @returns {any[][]} Data or some error.
 */
// @ts-ignore
async function WISEOPTIONS(symbols_or_contract_id, type, parameters, date) {
    if (symbols_or_contract_id.flat().length > 1000) {
        return [['Please select less than 1,000 tickers to get data.']]
    }
    // html encoding for ^
    const symbolsToCheck = symbols_or_contract_id
        .flat()
        .map((symbol: string) =>
            symbol.toUpperCase().replace('^', '%5E').trim()
        )
    for (let i = 0; i < symbolsToCheck.length; i++) {
        if (!symbolsToCheck[i]) {
            return [['Please enter a symbol.']]
        }
    }

    // Check if user is subscribed
    const { error } = checkToken(symbolsToCheck, demoTickers)
    if (error) {
        return error
    }

    try {
        const data = await httpClient.post(
            '/api/private/functions/wise-options',
            {
                symbols: symbols_or_contract_id,
                type,
                parameters,
                date: date == null ? null : [[excelDateToJSDate(date[0][0])]],
                platform: 'EXCEL',
            }
        )
        return data.data
    } catch (e) {
        return handleError(e)
    }
}

CustomFunctions.associate("WISEOPTIONS", WISEOPTIONS);