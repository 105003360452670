import { httpClient } from './http-client'
import { checkToken, excelDateToJSDate, handleError } from './utils'
import { demoTickers } from './constants'

/**
 * Get live and historical price data.
 * @customfunction
 * @helpurl https://blog.wisesheets.io/the-ultimate-guide-to-using-wisesheets/
 * @param {string[][]} symbols Company symbol(s).
 * @param {string[][]} parameters Data point(s) to retrieve.
 * @param {number} [numDaysToEnd] Number of days to end of date (optional).
 * @param {string} [startDate] Start date ie. 2018-03-12 (optional).
 * @param {string} [endDate] End date ie. 2019-03-12 (optional).
 * @returns {any[][]} Data or some error.
 */
// @ts-ignore
async function WISEPRICE(
    symbols,
    parameters,
    numDaysToEnd,
    startDate,
    endDate
) {
    if (symbols.flat().length > 1000) {
        return [['Please select less than 1,000 tickers to get data.']]
    }
    // html encoding for ^
    const symbolsToCheck = symbols
        .flat()
        .map((symbol: string) =>
            symbol.toUpperCase().replace('^', '%5E').trim()
        )
    for (let i = 0; i < symbolsToCheck.length; i++) {
        if (!symbolsToCheck[i]) {
            return [['Please enter a symbol.']]
        }
    }

    const { error } = checkToken(symbolsToCheck, demoTickers)
    if (error) {
        return error
    }

    try {
        const data = await httpClient.post(
            '/api/private/functions/wise-price',
            {
                symbols,
                parameters,
                numDaysToEnd,
                startDate:
                    startDate == null ? null : excelDateToJSDate(startDate),
                endDate: endDate == null ? null : excelDateToJSDate(endDate),
                platform: 'EXCEL',
            }
        )
        return data.data
    } catch (e) {
        return handleError(e)
    }
}

CustomFunctions.associate("WISEPRICE", WISEPRICE);