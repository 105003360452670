export function handleError(e: any) {
    const errorData = e.response
    if (errorData) {
        if (errorData.status === 400) {
            return [[errorData.data]]
        } else if (errorData.status === 401) {
            return [[errorData.data]]
        } else if (errorData.status === 404) {
            return [[errorData.data]]
        } else if (errorData.status === 409) {
            return [['Conflict.']]
        } else if (errorData.status === 429) {
            return [[errorData.data]]
        } else {
            // 500
            return [['Unexpected result. Verify your function call and retry.']]
        }
    } else {
        return [['Unexpected error. Try function with multiple tickers.']]
    }
}

export function checkToken(symbols: string[], demoTickersList: string[]) {
    const returnedObj = {
        error: null,
        decodedToken: null,
    }
    const token = window.localStorage.getItem('token')
    let decodedToken = null
    if (token) {
        let payload = token.split('.')[1]
        payload = window.atob(payload)
        decodedToken = JSON.parse(payload)
        returnedObj.decodedToken = decodedToken
        if (
            !decodedToken.isSubscribed &&
            symbols.some((r) => !demoTickersList.includes(r))
        ) {
            returnedObj.error = [
                [
                    'Please purchase a subscription to use Wisesheets with any symbol.',
                ],
            ]
        }
    } else {
        returnedObj.error = [
            ['Please login to use the WISE/WISEPRICE function.'],
        ]
    }
    return returnedObj
}

export function excelDateToJSDate(excelDate) {
    if (excelDate.includes('-') || excelDate.includes('/')) {
        const newDate = new Date(excelDate).toISOString().split('T')[0]
        return newDate
    } else {
        // this used to be + 1 but it was changed to + 2 to fix a bug, no fucking idea - i hate js dates
        var date = new Date(
            Math.round((excelDate - (25567 + 2)) * 86400 * 1000)
        )
        var converted_date = date.toISOString().split('T')[0]
        return converted_date
    }
}
